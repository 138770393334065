html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  border: 0;
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

*, :before, :after {
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

@font-face {
  font-family: Open Sauce;
  src: url("opensauce-medium.638d09c7.woff2") format("woff2"), url("opensauce-medium.638d09c7.woff2") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Open Sauce;
  src: url("opensauce-regular.f5d13f60.woff2") format("woff2"), url("opensauce-regular.f5d13f60.woff2") format("woff");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html {
  overscroll-behavior: none;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: .694444vw;
}

@media (width <= 550px) {
  html {
    font-size: 1.81818vw;
  }
}

@media (width <= 375px) {
  html {
    font-size: 2.66667vw;
  }
}

body {
  color: #aaa;
  background: #111;
  min-height: 100vh;
  font-family: Open Sauce, arial, sans-serif;
  font-size: 1.6rem;
  font-weight: 500;
}

html.has-scroll-smooth {
  overflow: hidden;
}

html.has-scroll-dragging {
  -webkit-user-select: none;
  user-select: none;
}

.has-scroll-smooth body {
  overflow: hidden;
}

.has-scroll-smooth [data-scroll-container] {
  min-height: 100vh;
}

.c-scrollbar {
  transform-origin: 100%;
  opacity: 0;
  width: 11px;
  height: 100vh;
  transition: transform .3s, opacity .3s;
  position: absolute;
  top: 0;
  right: 0;
}

.c-scrollbar:hover {
  transform: scaleX(1.45);
}

.c-scrollbar:hover, .has-scroll-scrolling .c-scrollbar, .has-scroll-dragging .c-scrollbar {
  opacity: 1;
}

.c-scrollbar_thumb {
  opacity: .5;
  cursor: -webkit-grab;
  cursor: grab;
  background-color: #000;
  border-radius: 10px;
  width: 7px;
  margin: 2px;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
}

.has-scroll-dragging .c-scrollbar_thumb {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

.home__hero .hero__title .bottom__left, .home__hero .hero__title__left, .home__hero .hero__title__right, .home__hero .hero__title__dash, .home__hero .hero__title h1.mobile {
  word-break: break-word;
  font-family: Bebas Neue, sans-serif;
  font-size: 32.8rem;
}

@media (width <= 550px) {
  .home__hero .hero__title .bottom__left, .home__hero .hero__title__left, .home__hero .hero__title__right, .home__hero .hero__title__dash, .home__hero .hero__title h1.mobile {
    font-size: 160px;
  }
}

.home__contact .marquee__inner .inner-span, .home__projects__project__title {
  letter-spacing: -.025em;
  font-family: Bebas Neue, sans-serif;
  font-size: 16rem;
}

@media (width <= 550px) {
  .home__contact .marquee__inner .inner-span, .home__projects__project__title {
    word-break: break-word;
    font-size: 80px;
  }
}

.home__awards .home__content__title, .home__awards .awards__title, .home__stack__paragraph .first-word, .home__content__title, .home__hero .hero__paragraph .first-word, .home__nav, .home__projects__project__label {
  font-family: Open Sauce, arial;
  font-size: 12px;
  line-height: 1.3;
}

.home__contact__email .to-copy, .home__contact__desc, .home__awards .home__content__desc .contact-scroll, .home__awards .home__content__desc, .home__awards .awards__item, .home__stack, .home__hero .hero__title .bottom__right {
  font-family: Open Sauce, arial;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.3;
}

@media (width <= 768px) {
  .home__contact__email .to-copy, .home__contact__desc, .home__awards .home__content__desc .contact-scroll, .home__awards .home__content__desc, .home__awards .awards__item, .home__stack, .home__hero .hero__title .bottom__right {
    font-size: 3rem;
  }
}

@media (width <= 550px) {
  .home__contact__email .to-copy, .home__contact__desc, .home__awards .home__content__desc .contact-scroll, .home__awards .home__content__desc, .home__awards .awards__item, .home__stack, .home__hero .hero__title .bottom__right {
    font-size: 16px;
  }
}

.home__contact__email .email, .home__content__desc .contact-scroll, .home__content__desc {
  letter-spacing: -.025em;
  font-family: Open Sauce, arial;
  font-size: 3.2rem;
  line-height: 1.3;
}

@media (width <= 550px) {
  .home__contact__email .email, .home__content__desc .contact-scroll, .home__content__desc {
    font-size: 32px;
  }
}

.home__footer__right a, .home__awards .home__content__desc a, .home__awards a, .home__stack__paragraph a, .home__content__desc a {
  color: #777;
  transition: color .3s;
}

@media (any-pointer: fine) {
  .home__footer__right a:hover, .home__awards a:hover, .home__stack__paragraph a:hover, .home__content__desc a:hover {
    color: #d42525;
  }
}

.home__projects__project {
  color: #777;
}

@media (any-pointer: fine) {
  .home__projects__project:hover .label__inner p:last-of-type {
    color: #aaa;
  }
}

.home__projects__project .project__link {
  text-align: right;
  padding-bottom: 3.1rem;
  padding-right: 5rem;
}

@media (width >= 551px) {
  .home__projects__project .project__link {
    display: none;
  }
}

.home__projects__project .project__link .c-button .c-link__inner > span {
  margin-top: -4px;
  display: block;
}

.home__projects__project .project__link .share-icon {
  position: relative;
  top: .3rem;
}

.home__projects__project__label {
  justify-content: flex-end;
  margin-top: 3.2rem;
  padding-right: 2.2rem;
  line-height: 1.3;
  display: flex;
}

.home__projects__project__label p {
  transition: color .3s;
}

@media (width >= 769px) {
  .home__projects__project__label {
    padding-right: 17.2rem;
  }
}

.home__projects__project__label .label-1 {
  justify-content: space-between;
  width: 100%;
  max-width: 84.3rem;
  display: flex;
}

.home__projects__project__link {
  pointer-events: none;
}

@media (any-pointer: fine) {
  .home__projects__project__link {
    pointer-events: auto;
  }
}

.home__projects__project__link.gradient .title__main {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(90deg, #e9e3c680 0%, #e9c6d980 100%) 0 0 / 200% 100%;
  -webkit-background-clip: text;
  transition: background .3s;
  animation: 5s infinite alternate textGradientAnim;
  overflow: hidden;
}

.home__projects__project__link.gradient .title__main .slide-up:before {
  -webkit-text-fill-color: transparent;
  background: #d42525;
  -webkit-background-clip: text;
}

@keyframes textGradientAnim {
  0% {
    background-position: 0;
  }

  50% {
    background-position: 100%;
  }

  100% {
    background-position: 0;
  }
}

.home__projects__project__link .title__main {
  position: relative;
}

@media (width <= 550px) {
  .home__projects__project__link .title__main.right {
    text-align: right;
  }
}

.home__projects__project__link .title__main .slide-up {
  transition: transform .275s;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateY(100%);
}

@media (any-pointer: fine) {
  .home__projects__project__link .title__main .slide-up {
    display: inline-block;
  }
}

.home__projects__project__link .title__main .slide-up:before {
  content: attr(data-content);
  color: #d42525;
  transition: transform .275s;
  display: inline-block;
  transform: translateY(-100%);
}

@media (width >= 769px) {
  .home__projects__project__link .title__main .slide-up:before {
    white-space: nowrap;
  }
}

.home__projects__project__link:hover .title__main .slide-up, .home__projects__project__link:hover .title__main .slide-up:before {
  transform: translateY(0);
}

.home__projects__project__title {
  text-transform: uppercase;
  padding-top: 6.4rem;
  padding-bottom: 4.5rem;
  line-height: 1;
}

@media (width >= 769px) {
  .home__projects__project__title {
    white-space: nowrap;
  }
}

@media (width <= 550px) {
  .home__projects__project__title {
    padding-top: 3.2rem;
    padding-bottom: 2.4rem;
    line-height: .9;
  }
}

.home__projects__project__title .inline-ovh {
  display: inline-flex;
  overflow: hidden;
}

.c-button {
  color: #aaa;
  text-transform: uppercase;
  cursor: pointer;
  background: none;
  border: 2px solid #777;
  border-radius: 45px;
  padding: 5px 8px;
  font-size: 16px;
  transition: all .3s;
  display: inline-block;
}

@media (any-pointer: fine) {
  .c-button:hover {
    color: #111;
    background: #d42525;
    border-color: #d42525;
    font-weight: 500;
  }
}

.c-button .c-link {
  z-index: 1;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.c-button .c-link__inner {
  margin-right: 2px;
  transition: transform .3s cubic-bezier(.175, .885, .32, 1.275);
  display: flex;
  transform: translateY(0);
}

.c-button .c-link__animated {
  font-family: Open Sauce, arial;
  display: none;
  position: absolute;
  top: 100%;
}

@media (any-pointer: fine) {
  .c-button .c-link__animated {
    display: inline-block;
  }

  .c-button:hover .c-link__inner {
    transform: translateY(-100%);
  }
}

.c-infinite .c-link {
  z-index: 1;
  align-items: center;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.c-infinite .c-link__inner {
  animation: 1s cubic-bezier(.175, .885, .32, 1.275) infinite infiniteArrow;
  display: flex;
  transform: translateY(-100%);
}

.c-infinite .c-link__animated {
  position: absolute;
  top: 100%;
}

@keyframes infiniteArrow {
  100% {
    transform: translateY(0);
  }
}

.home__contact__desc, .home__contact .line-wrapper, .home__awards, .home__content, .home__projects, .home__hero, .home__nav {
  padding-left: 4rem;
  padding-right: 4rem;
}

@media (width <= 550px) {
  .home__contact__desc, .home__contact .line-wrapper, .home__awards, .home__content, .home__projects, .home__hero, .home__nav {
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.home {
  visibility: hidden;
  padding: 2.4rem 0;
}

.home__nav {
  justify-content: space-between;
  margin-bottom: 6.4rem;
  line-height: 1.4;
  display: flex;
}

@media (width <= 550px) {
  .home__nav .hide-mobile {
    display: none;
  }
}

@media (width >= 551px) {
  .home__nav .hide-desktop {
    display: none;
  }
}

.home__nav .nav__button {
  flex-grow: 0;
  flex-shrink: 0;
}

.home__nav__left, .home__nav__right {
  text-transform: uppercase;
  justify-content: space-between;
}

@media (width >= 551px) {
  .home__nav__left, .home__nav__right {
    display: flex;
  }
}

.home__nav__left {
  width: 100%;
  max-width: 49rem;
  margin-right: 5rem;
}

@media (width <= 550px) {
  .home__nav__left {
    max-width: unset;
    margin-right: 2rem;
  }
}

.home__nav__right {
  width: 100%;
  max-width: 325px;
}

@media (width <= 550px) {
  .home__nav__right {
    width: 100%;
    max-width: 175px;
    margin-left: auto;
  }

  .home__nav__right br {
    display: none;
  }
}

.home__nav .nav__folio, .home__nav .nav__availability {
  color: #777;
}

@media (width <= 550px) {
  .home__nav .nav__folio, .home__nav .nav__availability {
    margin-bottom: 2.4rem;
  }

  .home__nav .nav__folio br, .home__nav .nav__availability br {
    display: none;
  }
}

.home__hero {
  margin-bottom: 9.6rem;
}

.home__hero .hero__title {
  color: #777;
  line-height: .9;
}

@media (width <= 550px) {
  .home__hero .hero__title h1.desktop {
    display: none;
  }
}

.home__hero .hero__title h1.mobile {
  letter-spacing: -.05em;
  word-wrap: break-word;
}

@media (width >= 551px) {
  .home__hero .hero__title h1.mobile {
    display: none;
  }
}

.home__hero .hero__title .hero__hover {
  transition: color .2s;
}

@media (any-pointer: fine) {
  .home__hero .hero__title .hero__hover:hover {
    color: #aaa;
  }
}

.home__hero .hero__title__left, .home__hero .hero__title__right, .home__hero .hero__title__dash {
  letter-spacing: -.105em;
  display: inline-block;
}

.home__hero .hero__title__dash {
  will-change: transform;
  transform: scaleX(1.05);
}

@media (width <= 768px) {
  .home__hero .hero__title__dash.desktop {
    display: none;
  }
}

@media (width >= 769px) {
  .home__hero .hero__title__dash.tablet {
    display: none;
  }
}

.home__hero .hero__title__top {
  justify-content: space-between;
  padding-right: 3rem;
  display: flex;
}

.home__hero .hero__title__top, .home__hero .hero__title__bottom {
  width: 100%;
}

.home__hero .hero__title__top.overflow, .home__hero .hero__title__bottom.overflow {
  width: 100%;
  display: inline-flex;
  overflow: hidden;
}

.home__hero .hero__title__bottom {
  justify-content: space-between;
}

@media (width >= 769px) {
  .home__hero .hero__title__bottom {
    display: flex;
  }
}

.home__hero .hero__title .bottom__left {
  letter-spacing: -.105em;
  flex-shrink: 0;
}

.home__hero .hero__title .bottom__right {
  z-index: 1;
  flex-direction: column;
  width: 100%;
  max-width: 32.5rem;
  padding: 2rem 0 5rem;
  display: flex;
  position: relative;
}

@media (width <= 768px) {
  .home__hero .hero__title .bottom__right {
    max-width: 55rem;
    padding-bottom: 0;
  }
}

@media (width <= 550px) {
  .home__hero .hero__title .bottom__right {
    max-width: 255px;
    margin-left: auto;
  }
}

.home__hero .hero__paragraph {
  color: #aaa;
}

.home__hero .hero__paragraph span {
  text-transform: uppercase;
}

.home__hero .hero__paragraph .first-word {
  color: #777;
}

.home__hero .hero__scroll {
  color: #777;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
  display: flex;
}

@media (width <= 768px) {
  .home__hero .hero__scroll {
    display: none;
  }
}

.home__projects__line {
  visibility: hidden;
  width: 100%;
  display: inline-block;
  position: relative;
}

.home__projects__line span {
  transform-origin: 0 0;
  background: #777;
  width: 100%;
  height: 2px;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.home__projects__line.right span {
  transform-origin: 100% 0;
}

.home__content {
  padding-top: 24rem;
  padding-bottom: 24rem;
}

@media (width <= 550px) {
  .home__content {
    padding-top: 16rem;
    padding-bottom: 16rem;
  }
}

@media (width >= 769px) {
  .home__content {
    text-align: center;
  }
}

.home__content__title {
  text-transform: uppercase;
  color: #777;
  margin-bottom: 2.4rem;
}

.home__content__desc {
  width: 100%;
  max-width: 95rem;
}

@media (width >= 769px) {
  .home__content__desc {
    margin: 0 auto;
  }
}

.home__content__desc .contact-scroll {
  color: #777;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  font-weight: 500;
  transition: color .3s;
}

@media (any-pointer: fine) {
  .home__content__desc .contact-scroll:hover {
    color: #d42525;
  }
}

.home__stack {
  max-width: 104.4rem;
  margin-left: auto;
  padding-top: 16rem;
  padding-bottom: 24rem;
}

@media (width <= 550px) {
  .home__stack {
    padding-top: 9.6rem;
    padding-bottom: 16rem;
  }
}

.home__stack__paragraph {
  color: #aaa;
  max-width: 32.5rem;
}

@media (width <= 768px) {
  .home__stack__paragraph {
    max-width: 55rem;
  }
}

@media (width <= 550px) {
  .home__stack__paragraph {
    max-width: 35rem;
    margin-left: auto;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
  }
}

.home__stack__paragraph span, .home__stack__paragraph a {
  color: #777;
  text-transform: uppercase;
}

.home__awards {
  justify-content: space-between;
  max-width: 113rem;
  margin-left: auto;
  margin-right: auto;
  padding-top: 9.6rem;
  padding-bottom: 9.6rem;
}

@media (width >= 769px) {
  .home__awards {
    padding-top: 24rem;
    padding-bottom: 24rem;
    display: flex;
  }
}

.home__awards__top, .home__awards__bottom {
  justify-content: space-between;
}

@media (width >= 551px) {
  .home__awards__top, .home__awards__bottom {
    display: flex;
  }
}

.home__awards .awards__title {
  color: #777;
  margin-bottom: 24px;
}

.home__awards .awards__item {
  border-top: 2px solid #777;
  padding: 16px 0;
  transition: color .2s;
  position: relative;
}

.home__awards__stack, .home__awards__left {
  margin-bottom: 9.6rem;
}

@media (width >= 769px) {
  .home__awards__left {
    width: 100%;
    max-width: 55.5rem;
    margin-bottom: 0;
  }

  .home__awards__right {
    width: 100%;
    max-width: 32.5rem;
  }
}

.home__awards .home__content__title {
  text-transform: uppercase;
  color: #777;
}

.home__awards .home__content__desc {
  width: 100%;
  max-width: 95rem;
}

.home__awards .home__content__desc a {
  text-transform: uppercase;
}

.home__awards .home__content__desc .contact-scroll {
  color: #777;
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  font-weight: 500;
  transition: color .3s;
}

@media (any-pointer: fine) {
  .home__awards .home__content__desc .contact-scroll:hover {
    color: #d42525;
  }
}

@media (width >= 769px) {
  .home__contact {
    padding-top: 16rem;
  }
}

.home__contact__desc {
  margin: 0 0 6.4rem;
}

@media (width >= 551px) {
  .home__contact__desc {
    text-align: center;
    max-width: 44rem;
    margin-left: auto;
    margin-right: auto;
    padding: 0;
  }
}

.home__contact .marquee {
  text-transform: uppercase;
  overflow: hidden;
}

.home__contact .marquee__wrap:hover .marquee__inner {
  animation-play-state: paused;
}

.home__contact .marquee__wrap:hover .marquee__inner:hover .slide-up, .home__contact .marquee__wrap:hover .marquee__inner:hover .slide-up:before {
  transform: translateY(0);
}

.home__contact .marquee__inner {
  will-change: transform;
  align-items: center;
  width: fit-content;
  height: 100%;
  padding-top: 2.4rem;
  padding-bottom: 1rem;
  animation: 25s linear infinite marquee;
  display: flex;
  position: relative;
}

@media (width >= 551px) {
  .home__contact .marquee__inner {
    padding-top: 5.8rem;
    padding-bottom: 2.3rem;
  }
}

.home__contact .marquee__inner span {
  white-space: nowrap;
  padding-left: 2rem;
  padding-right: 2rem;
}

.home__contact .marquee__inner .inner-span {
  text-transform: uppercase;
  color: #777;
  position: relative;
  overflow: hidden;
}

.home__contact .marquee__inner .inner-span .slide-up {
  transition: transform .275s;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  transform: translateY(100%);
}

@media (any-pointer: fine) {
  .home__contact .marquee__inner .inner-span .slide-up {
    display: inline-block;
  }
}

.home__contact .marquee__inner .inner-span .slide-up:before {
  content: attr(data-content);
  white-space: nowrap;
  color: #d42525;
  transition: transform .275s;
  display: inline-block;
  transform: translateY(-100%);
}

@keyframes marquee {
  100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.home__contact__email {
  text-align: center;
  padding-top: 4.8rem;
  padding-bottom: 7.2rem;
}

@media (width <= 550px) {
  .home__contact__email {
    text-align: left;
    word-break: break-word;
    width: 100%;
    max-width: 255px;
    margin-left: auto;
  }
}

.home__contact__email .to-copy {
  display: none;
}

@media (any-pointer: fine) {
  .home__contact__email .to-copy {
    opacity: 0;
    text-transform: uppercase;
    transition: opacity .3s, transform .3s;
    display: block;
    transform: translateY(100%);
  }
}

.home__contact__email .to-copy span {
  border: 2px solid #777;
  border-radius: 45px;
  padding: 5px 8px;
}

.home__contact__email .email {
  cursor: pointer;
  color: #aaa;
  background: none;
  border: 0;
  margin-bottom: 1.6rem;
  padding: 0;
  font-weight: 500;
}

@media (width <= 768px) {
  .home__contact__email .email {
    text-align: left;
  }
}

@media (any-pointer: fine) {
  .home__contact__email .email:hover {
    color: #d42525;
  }
}

.home__contact__email .email:hover + .to-copy {
  opacity: 1;
  transform: translateY(0);
}

.home__footer {
  justify-content: center;
  padding-bottom: 4rem;
  font-size: 16px;
  font-weight: 400;
  position: relative;
}

@media (width >= 769px) {
  .home__footer {
    display: flex;
  }
}

@media (width <= 768px) {
  .home__footer {
    width: 100%;
    max-width: 40rem;
    margin-left: auto;
  }
}

@media (width <= 550px) {
  .home__footer {
    width: 100%;
    max-width: 255px;
  }
}

.home__footer__left {
  font-family: Open Sauce, arial;
  display: flex;
}

@media (width >= 769px) {
  .home__footer__left {
    position: absolute;
    top: 1rem;
    left: 2.4rem;
    right: 0;
  }
}

.home__footer__left .place {
  color: #777;
  margin-right: 1.4rem;
}

.home__footer__left .time {
  position: relative;
}

.home__footer__left .time:before {
  content: "";
  background: #111;
  width: 6px;
  height: 100%;
  position: absolute;
  left: 0;
}

@media (width <= 768px) {
  .home__footer__left .time {
    margin-bottom: 2.4rem;
  }
}

@media (width >= 769px) {
  .home__footer__right {
    position: absolute;
    top: 1rem;
    right: 2.4rem;
  }
}

.home__footer__center .footer__links {
  align-items: center;
}

@media (width >= 769px) {
  .home__footer__center .footer__links {
    display: flex;
  }
}

@media (width <= 768px) {
  .home__footer__center .footer__links {
    margin-bottom: 4.8rem;
  }
}

.home__footer__center .footer__links .link__flex {
  margin-left: auto;
  display: flex;
}

@media (width <= 768px) {
  .home__footer__center .footer__links .link__flex {
    width: 100%;
    max-width: 40rem;
  }
}

@media (width <= 550px) {
  .home__footer__center .footer__links .link__flex {
    width: 100%;
    max-width: 255px;
  }
}

.home__footer__center .footer__links .link__flex__inner {
  display: flex;
}

@media (width <= 768px) {
  .home__footer__center .footer__links .link__flex__inner .c-button:first-of-type {
    margin-right: .8rem;
  }

  .home__footer__center .footer__links .link__flex__inner.second .c-button:first-of-type {
    margin-right: 0;
  }

  .home__footer__center .footer__links .link__flex__inner.second .c-button:last-of-type {
    margin-right: .8rem;
  }
}

@media (width >= 769px) {
  .home__footer__center .footer__links .link__flex__inner:last-of-type {
    margin-left: .8rem;
  }
}

@media (width <= 768px) {
  .home__footer__center .footer__links .link__flex__inner.second {
    flex-direction: row-reverse;
    margin-top: 1.6rem;
  }
}

@media (width >= 769px) {
  .home__footer__center .footer__links .c-button:not(:last-of-type) {
    margin-right: .8rem;
  }
}
/*# sourceMappingURL=index.7c7e877f.css.map */
